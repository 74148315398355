<template>
  <div class="signup">
    <div class="signup_body">
      <!-- {/* Signup text */} -->
      <div class="signup_body_text">
        <!-- {/* logo */} -->
        <div class="signup_body_text_logo">
          <div class="signup_body_text_logo_inner">
            <img src="../../assets/logo3.png" alt="logo" />
          </div>
        </div>
        <div class="signup_body_text_container">
          <div>
            <p>Source, pay and ship your goods — all in one platform!</p>
          </div>
        </div>
      </div>

      <!-- {/* Form Input fields */} -->
      <div class="signup_body_form">
        <div class="signup_body_form_container">
          <!-- {/* mobile logo */} -->
          <div class="signup_body_text_logo_mobile">
            <div class="signup_body_text_logo_mobile_inner">
              <img src="../../assets/logo2.png" alt="logo" />
            </div>
          </div>
          <h3>Create a Free Account</h3>

          <div class="signup_body_form_container_inputs">
            <form @submit.prevent="signupFormSubmit">
              <div class="signup_body_form_container_inputs_container">
                <div class="input">
                  <input
                    required
                    placeholder="First Name"
                    class="input_field"
                    v-model="firstName"
                    type="text"
                  />
                </div>

                <div class="input">
                  <input
                    v-model="lastName"
                    required
                    placeholder="Last Name"
                    class="input_field"
                    type="text"
                  />
                </div>
              </div>

              <div class="signup_body_form_container_inputs_container">
                <div class="input">
                  <input
                    v-model="businessName"
                    placeholder="Business Name"
                    class="input_field"
                    type="text"
                  />
                </div>
              </div>

              <div class="signup_body_form_container_inputs_container">
                <div class="shipments_select_wrapper">
                  <select
                    required
                    v-model="industry"
                    :items="industries"
                    class="input_field"
                  >
                    <option value="">Industry</option>
                    <option
                      v-for="industry in industries"
                      :key="industry?.id"
                      :value="industry.name"
                    >
                      {{ industry.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="signup_body_form_container_inputs_container">
                <div class="input">
                  <vue-tel-input
                    v-model="number"
                    :inputOptions="inputOptions"
                    :dropdownOptions="dropdownOptions"
                    ref="vueTelInput"
                  />
                </div>
              </div>

              <div class="signup_body_form_container_inputs_container">
                <div class="shipments_select_wrapper">
                  <select
                    required
                    v-model="location"
                    :items="countries"
                    class="input_field"
                  >
                    <option value="">Country</option>
                    <option
                      v-for="country in countries"
                      :key="country.code"
                      :value="country.name"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="signup_body_form_container_inputs_container">
                <div class="input">
                  <input
                    required
                    v-model="email"
                    type="email"
                    placeholder="Email Address"
                    class="input_field"
                  />
                </div>
              </div>

              <div class="signup_body_form_container_inputs_container">
                <div class="input">
                  <input
                    v-model="password"
                    required
                    :type="passwordIconToggle ? 'text' : 'password'"
                    placeholder="Password"
                    class="input_field"
                  />

                  <svg
                    @click="togglePassword"
                    v-if="passwordIconToggle"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_1998)">
                      <path
                        d="M12.0002 3C17.3922 3 21.8782 6.88 22.8192 12C21.8792 17.12 17.3922 21 12.0002 21C6.60815 21 2.12215 17.12 1.18115 12C2.12115 6.88 6.60815 3 12.0002 3ZM12.0002 19C14.0396 18.9996 16.0185 18.3068 17.613 17.0352C19.2075 15.7635 20.3231 13.9883 20.7772 12C20.3214 10.0133 19.2051 8.24 17.6108 6.97003C16.0165 5.70005 14.0385 5.00853 12.0002 5.00853C9.96185 5.00853 7.98384 5.70005 6.38953 6.97003C4.79521 8.24 3.67892 10.0133 3.22315 12C3.67725 13.9883 4.79283 15.7635 6.3873 17.0352C7.98177 18.3068 9.96068 18.9996 12.0002 19ZM12.0002 16.5C10.8067 16.5 9.66209 16.0259 8.81817 15.182C7.97426 14.3381 7.50015 13.1935 7.50015 12C7.50015 10.8065 7.97426 9.66193 8.81817 8.81802C9.66209 7.97411 10.8067 7.5 12.0002 7.5C13.1936 7.5 14.3382 7.97411 15.1821 8.81802C16.026 9.66193 16.5002 10.8065 16.5002 12C16.5002 13.1935 16.026 14.3381 15.1821 15.182C14.3382 16.0259 13.1936 16.5 12.0002 16.5ZM12.0002 14.5C12.6632 14.5 13.2991 14.2366 13.7679 13.7678C14.2368 13.2989 14.5002 12.663 14.5002 12C14.5002 11.337 14.2368 10.7011 13.7679 10.2322C13.2991 9.76339 12.6632 9.5 12.0002 9.5C11.3371 9.5 10.7012 9.76339 10.2324 10.2322C9.76355 10.7011 9.50015 11.337 9.50015 12C9.50015 12.663 9.76355 13.2989 10.2324 13.7678C10.7012 14.2366 11.3371 14.5 12.0002 14.5Z"
                        fill="#3E414C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_1998">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <svg
                    v-else
                    @click="togglePassword"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_2001)">
                      <path
                        d="M17.8822 19.297C16.1234 20.4126 14.0829 21.0033 12.0002 21C6.60815 21 2.12215 17.12 1.18115 12C1.61118 9.67072 2.78278 7.5429 4.52115 5.93401L1.39215 2.80801L2.80715 1.39301L22.6062 21.193L21.1912 22.607L17.8822 19.297ZM5.93515 7.35001C4.57616 8.5856 3.62947 10.2088 3.22315 12C3.53545 13.3665 4.16242 14.6411 5.05416 15.7226C5.94589 16.804 7.07778 17.6624 8.3597 18.2293C9.64163 18.7962 11.0382 19.0561 12.4382 18.9881C13.8383 18.9202 15.2031 18.5264 16.4242 17.838L14.3962 15.81C13.5328 16.3538 12.5104 16.5881 11.4964 16.4744C10.4824 16.3608 9.53719 15.9059 8.81572 15.1844C8.09425 14.463 7.63938 13.5177 7.52571 12.5038C7.41205 11.4898 7.64634 10.4673 8.19015 9.60401L5.93515 7.35001ZM12.9142 14.328L9.67215 11.086C9.49422 11.5389 9.45234 12.034 9.55166 12.5104C9.65098 12.9867 9.88717 13.4238 10.2313 13.7679C10.5754 14.112 11.0124 14.3482 11.4888 14.4475C11.9652 14.5468 12.4602 14.5049 12.9132 14.327L12.9142 14.328ZM20.8072 16.592L19.3762 15.162C20.0446 14.2093 20.5205 13.1352 20.7772 12C20.5054 10.8097 19.9944 9.68715 19.2753 8.7005C18.5562 7.71385 17.6439 6.88373 16.594 6.26061C15.5441 5.63749 14.3784 5.23437 13.1679 5.07577C11.9573 4.91717 10.7272 5.00638 9.55215 5.33801L7.97415 3.76001C9.22115 3.27001 10.5802 3.00001 12.0002 3.00001C17.3922 3.00001 21.8782 6.88001 22.8192 12C22.5127 13.6657 21.824 15.2376 20.8072 16.592ZM11.7232 7.50801C12.3597 7.46867 12.9973 7.56507 13.5937 7.79082C14.1901 8.01657 14.7318 8.36652 15.1827 8.81746C15.6336 9.2684 15.9836 9.81003 16.2093 10.4065C16.4351 11.0029 16.5315 11.6405 16.4922 12.277L11.7232 7.50801Z"
                        fill="#3E414C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_2001">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <p
                v-if="password.length > 0 && password.length < 6"
                class="password_error_message"
              >
                Passowrd must be 6 characters long
              </p>

              <div class="signup_body_form_container_inputs_container">
                <div class="input">
                  <input
                    v-model="confirmPassword"
                    required
                    :type="confirmPasswordIconToggle ? 'text' : 'password'"
                    placeholder="Confirm Password"
                    class="input_field"
                  />
                  <svg
                    @click="toggleConfirmPassword"
                    v-if="confirmPasswordIconToggle"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_1998)">
                      <path
                        d="M12.0002 3C17.3922 3 21.8782 6.88 22.8192 12C21.8792 17.12 17.3922 21 12.0002 21C6.60815 21 2.12215 17.12 1.18115 12C2.12115 6.88 6.60815 3 12.0002 3ZM12.0002 19C14.0396 18.9996 16.0185 18.3068 17.613 17.0352C19.2075 15.7635 20.3231 13.9883 20.7772 12C20.3214 10.0133 19.2051 8.24 17.6108 6.97003C16.0165 5.70005 14.0385 5.00853 12.0002 5.00853C9.96185 5.00853 7.98384 5.70005 6.38953 6.97003C4.79521 8.24 3.67892 10.0133 3.22315 12C3.67725 13.9883 4.79283 15.7635 6.3873 17.0352C7.98177 18.3068 9.96068 18.9996 12.0002 19ZM12.0002 16.5C10.8067 16.5 9.66209 16.0259 8.81817 15.182C7.97426 14.3381 7.50015 13.1935 7.50015 12C7.50015 10.8065 7.97426 9.66193 8.81817 8.81802C9.66209 7.97411 10.8067 7.5 12.0002 7.5C13.1936 7.5 14.3382 7.97411 15.1821 8.81802C16.026 9.66193 16.5002 10.8065 16.5002 12C16.5002 13.1935 16.026 14.3381 15.1821 15.182C14.3382 16.0259 13.1936 16.5 12.0002 16.5ZM12.0002 14.5C12.6632 14.5 13.2991 14.2366 13.7679 13.7678C14.2368 13.2989 14.5002 12.663 14.5002 12C14.5002 11.337 14.2368 10.7011 13.7679 10.2322C13.2991 9.76339 12.6632 9.5 12.0002 9.5C11.3371 9.5 10.7012 9.76339 10.2324 10.2322C9.76355 10.7011 9.50015 11.337 9.50015 12C9.50015 12.663 9.76355 13.2989 10.2324 13.7678C10.7012 14.2366 11.3371 14.5 12.0002 14.5Z"
                        fill="#3E414C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_1998">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <svg
                    v-else
                    @click="toggleConfirmPassword"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_2001)">
                      <path
                        d="M17.8822 19.297C16.1234 20.4126 14.0829 21.0033 12.0002 21C6.60815 21 2.12215 17.12 1.18115 12C1.61118 9.67072 2.78278 7.5429 4.52115 5.93401L1.39215 2.80801L2.80715 1.39301L22.6062 21.193L21.1912 22.607L17.8822 19.297ZM5.93515 7.35001C4.57616 8.5856 3.62947 10.2088 3.22315 12C3.53545 13.3665 4.16242 14.6411 5.05416 15.7226C5.94589 16.804 7.07778 17.6624 8.3597 18.2293C9.64163 18.7962 11.0382 19.0561 12.4382 18.9881C13.8383 18.9202 15.2031 18.5264 16.4242 17.838L14.3962 15.81C13.5328 16.3538 12.5104 16.5881 11.4964 16.4744C10.4824 16.3608 9.53719 15.9059 8.81572 15.1844C8.09425 14.463 7.63938 13.5177 7.52571 12.5038C7.41205 11.4898 7.64634 10.4673 8.19015 9.60401L5.93515 7.35001ZM12.9142 14.328L9.67215 11.086C9.49422 11.5389 9.45234 12.034 9.55166 12.5104C9.65098 12.9867 9.88717 13.4238 10.2313 13.7679C10.5754 14.112 11.0124 14.3482 11.4888 14.4475C11.9652 14.5468 12.4602 14.5049 12.9132 14.327L12.9142 14.328ZM20.8072 16.592L19.3762 15.162C20.0446 14.2093 20.5205 13.1352 20.7772 12C20.5054 10.8097 19.9944 9.68715 19.2753 8.7005C18.5562 7.71385 17.6439 6.88373 16.594 6.26061C15.5441 5.63749 14.3784 5.23437 13.1679 5.07577C11.9573 4.91717 10.7272 5.00638 9.55215 5.33801L7.97415 3.76001C9.22115 3.27001 10.5802 3.00001 12.0002 3.00001C17.3922 3.00001 21.8782 6.88001 22.8192 12C22.5127 13.6657 21.824 15.2376 20.8072 16.592ZM11.7232 7.50801C12.3597 7.46867 12.9973 7.56507 13.5937 7.79082C14.1901 8.01657 14.7318 8.36652 15.1827 8.81746C15.6336 9.2684 15.9836 9.81003 16.2093 10.4065C16.4351 11.0029 16.5315 11.6405 16.4922 12.277L11.7232 7.50801Z"
                        fill="#3E414C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_2001">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>

              <div class="signup_body_form_container_inputs_container">
                <div class="input">
                  <input
                    v-model="referralCode"
                    type="text"
                    placeholder="Referral Code (optional)"
                    class="input_field"
                  />
                </div>
              </div>

              <div class="signup_body_form_container_inputs_container">
                <input required type="checkbox" />
                <p>
                  I agree to Novoinno's
                  <a
                    href="https://wry-anemone-211.notion.site/Terms-and-Conditions-18e18f3065c780a99795f670a962aadc"
                    target="_blank"
                    >Terms</a
                  >
                  and
                  <a
                    href="https://wry-anemone-211.notion.site/Privacy-Policy-18e18f3065c780589041d201b2de4c0b"
                    target="_blank"
                    >Privacy Policy</a
                  >
                </p>
              </div>

              <div v-if="!isLoading" class="button">
                <button class="button_btn">Sign Up</button>
              </div>
              <div v-else class="button">
                <button :disabled="isLoading" class="button_btn">
                  <div class="loading-circle"></div>
                </button>
              </div>
            </form>
          </div>

          <router-link to="/login">
            Already have an account? <span>Login</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthApi from "../../utils/api/AuthApi";
import Countries from "../../utils/Countries";

export default {
  data() {
    return {
      firstName: "",
      password: "",
      confirmPassword: "",
      number: "",
      countryCode: "",
      lastName: "",
      businessName: "",
      location: "",
      industry: "",
      email: "",
      referralCode: "",
      countries: [],
      industries: [
        { id: 1, name: "Fashion and Apparel" },
        { id: 2, name: "Information Technology" },
        { id: 3, name: "Healthcare" },
        { id: 4, name: "Retail" },
        { id: 5, name: "Finance" },
        { id: 6, name: "Manufacturing" },
        { id: 7, name: "Real Estate" },
        { id: 8, name: "Hospitality" },
        { id: 9, name: "Education" },
        { id: 10, name: "Transportation" },
        { id: 11, name: "Energy" },
        { id: 12, name: "Media and Entertainment" },
        { id: 13, name: "Telecommunications" },
        { id: 14, name: "Agriculture" },
        { id: 15, name: "Construction" },
        { id: 16, name: "Automotive" },
        { id: 17, name: "Pharmaceuticals" },
        { id: 18, name: "Food and Beverage" },
        { id: 19, name: "Environmental Services" },
        { id: 20, name: "Consulting" },
      ],
      isLoading: false,
      passwordIconToggle: false,
      confirmPasswordIconToggle: false,
      inputOptions: {
        placeholder: "Enter business phone number",
        showFlags: true,
        showDialCode: true,
        showDialCodeInSelection: true,
        showSearchBox: true,
        styleClasses: "input_field",
        required: true,
      },
      dropdownOptions: {
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showFlags: true,
        showSearchBox: true,
      },
    };
  },
  mounted() {
    this.getCountries();
    this.getReferralCode();
  },
  methods: {
    getCountries() {
      this.countries = Countries;
    },

    getReferralCode() {
      this.referralCode = this.$route?.query?.referralCode;
    },

    togglePassword() {
      this.passwordIconToggle = !this.passwordIconToggle;
    },

    toggleConfirmPassword() {
      this.confirmPasswordIconToggle = !this.confirmPasswordIconToggle;
    },

    // Submit registeration form function
    async signupFormSubmit() {
      this.isLoading = true;
      try {
        const res = await AuthApi.registerUserHandler({
          firstName: this.firstName,
          referralCode: this.referralCode,
          password: this.password,
          businessPhoneNumber: this.number.replace(/\s/g, ""),
          lastName: this.lastName,
          businessName: this.businessName,
          businessLocation: this.location,
          email: this.email,
          industry: this.industry,
          confirmPassword: this.confirmPassword,
        });

        this.isLoading = false;
        this.$toast.success(
          `Sign up successful! You'll receive a confirmation email shortly.`
        );
        setTimeout(() => {
          this.$router.push("/verify-email");
        }, 2000);
        return res;
      } catch (err) {
        this.isLoading = false;
        this.$toast.error(err.response?.data?.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.signup {
  display: flex;
  flex-direction: column;

  .signup_body {
    display: flex;
    width: 100%;
    height: 100vh;

    @media only screen and (max-width: 920px) {
      height: auto;
    }

    .signup_body_text {
      width: 32.25em;
      padding: 2.75rem 3.1875rem;
      display: flex;
      flex-direction: column;
      background-color: $black-color;
      height: auto;

      @media only screen and (max-width: 920px) {
        display: none;
      }

      .signup_body_text_logo {
        display: flex;
        width: 10.3125em;
        height: 3.6875em;
        margin-bottom: 2.375rem;
        justify-content: center;
        align-items: center;

        .signup_body_text_logo_inner {
          width: 100%;
          display: flex;
          height: auto;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .signup_body_text_container {
        display: flex;
        flex-direction: column;
        gap: 3.5625rem;
        height: 100vh;

        div {
          margin: auto 0px;
          p {
            color: $lilac-color;
            font-family: $primary-font;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.6;
            margin-bottom: 5rem;
          }
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          width: 20.25em;
          h3 {
            color: $black-color;
            font-family: $primary-font;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }

    .signup_body_form {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100vh;
      flex: 1;
      .signup_body_text_logo_mobile {
        display: none;

        @media only screen and (max-width: 920px) {
          display: flex;
          margin: auto;
          width: 10.3125em;
          height: 3.6875em;
          margin-bottom: 4.5625rem;
          justify-content: center;
          align-items: center;

          .signup_body_text_logo_mobile_inner {
            width: 100%;
            display: flex;
            height: auto;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .signup_body_form_container {
        margin: auto;
        display: flex;
        flex-direction: column;
        width: 23.375em;

        @media only screen and (max-width: 920px) {
          margin: auto;
          width: 19.375em;
          height: auto;
        }

        .signup_body_form_container_inputs {
          margin-bottom: 1rem;
          display: flex;
          flex-direction: column;

          form {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 1.25rem;

            .loading-circle {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              border: 2px solid #fff;
              position: relative;
              animation: rotate 1s linear infinite;
            }

            .loading-circle::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #fff;
              transform: translate(-50%, -50%);
              animation: bounce 0.6s linear infinite;
            }

            @keyframes rotate {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }

            @keyframes bounce {
              0%,
              100% {
                transform: scale(1);
              }
              50% {
                transform: scale(0);
              }
            }

            .signup_body_form_container_inputs_container {
              display: flex;
              gap: 1.5rem;
              width: 100%;

              .input {
                width: 100%;
              }

              .shipments_select_wrapper {
                border: 1px solid #dedce9;
                padding: 5px 0rem;
                width: 100%;
                height: 3.125em;
                display: flex;
                border-radius: 0.375rem;
                background-color: #fefefe;

                select {
                  border-radius: 0.3125rem;
                  width: 100%;
                  height: auto;
                  background-color: #fefefe;
                  padding: 0rem 1rem;
                  color: #363539;
                  border: none;
                  border: 1px solid transparent;

                  font-family: $secondary-font;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;

                  &:focus {
                    outline: none;
                  }
                }
              }

              p {
                font-size: 0.75rem;
                font-weight: 400;
                font-family: $secondary-font;

                a {
                  text-decoration: none;
                  text-align: center;
                  margin-bottom: 0.9375rem;
                  color: $purple-color;
                }
              }
            }
            .password_error_message {
              color: rgb(255, 58, 58);
              font-size: 0.875rem;
              text-decoration: dashed;
            }
          }
        }

        h3 {
          color: $black-color;
          font-family: $primary-font;
          font-size: 2rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: center;
          margin-bottom: 2.625rem;

          @media only screen and (max-width: 920px) {
            font-size: 1.375rem;
          }
        }

        a {
          text-decoration: none;
          text-align: center;
          margin-bottom: 0.9375rem;
          color: $grey-link-color;
          font-family: $secondary-font;

          @media only screen and (max-width: 920px) {
            font-size: 0.75rem;
          }
          span {
            color: $purple-color;
          }
        }
      }
    }
  }
}
</style>
