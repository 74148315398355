<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="addresses">
    <div class="addresses_wrapper">
      <div class="addresses_wrapper_menu">
        <p>
          Use the information below as your shipping address when shopping
          online. We’ll receive and process the package in your name
        </p>
        <div class="shipments_select">
          <div class="shipments_select_wrapper">
            <select required v-model="address" class="input_field">
              <option value="">Select Shipping Address</option>
              <option value="uk">UK to Nigeria</option>
              <option value="usa">U.S to Nigeria</option>
              <option value="china">China to Nigeria</option>
              <option value="canada">Canada to Nigeria</option>
              <option value="nigeria">Export from Nigeria</option>
            </select>
          </div>
        </div>
      </div>

      <div class="addresses_wrapper_inner" v-if="address.length !== 0">
        <div class="shipments_select">
          <label>Full Name</label>
          <div class="input">
            <input
              required
              :value="
                selectedAddress.name ||
                `${userDetails.firstName} ${userDetails.lastName} ${
                  address === 'uk' ? `NTAF` : `NVAF`
                }`
              "
              disabled
              class="input_field"
            />
            <svg
              @click="
                copy(
                  this.selectedAddress.name ||
                    `${userDetails.firstName} ${userDetails.lastName} ${
                      address === 'uk' ? `NTAF` : `NSAF`
                    }`
                )
              "
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_2013)">
                <path
                  d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z"
                  fill="#3E414C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2013">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div class="shipments_select">
          <label>Phone Number</label>
          <div class="input">
            <input
              required
              :value="
                selectedAddress.key === 'nigeria'
                  ? userDetails?.businessPhoneNumber
                  : selectedAddress.phoneNumber
              "
              disabled
              class="input_field"
            />
            <svg
              @click="
                copy(
                  selectedAddress.key === 'nigeria'
                    ? userDetails?.businessPhoneNumber
                    : this.selectedAddress.phoneNumber
                )
              "
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_2013)">
                <path
                  d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z"
                  fill="#3E414C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2013">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div class="shipments_select">
          <label>Address Line 1 </label>
          <div class="input">
            <input
              required
              :value="selectedAddress.addressLine"
              disabled
              class="input_field"
            />
            <svg
              @click="copy(this.selectedAddress.addressLine)"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_2013)">
                <path
                  d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z"
                  fill="#3E414C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2013">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div class="shipments_select">
          <label>ZIP </label>
          <div class="input">
            <input
              required
              :value="selectedAddress.zip"
              disabled
              class="input_field"
            />
            <svg
              @click="copy(this.selectedAddress.zip)"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_2013)">
                <path
                  d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z"
                  fill="#3E414C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2013">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div class="shipments_select">
          <label>Address Line 2 </label>
          <div class="input">
            <input
              required
              :value="selectedAddress.addressLine2"
              disabled
              class="input_field"
            />
            <svg
              @click="copy(this.selectedAddress.addressLine2)"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_2013)">
                <path
                  d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z"
                  fill="#3E414C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2013">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div class="shipments_select">
          <label>Country </label>
          <div class="input">
            <input
              required
              :value="selectedAddress.country"
              disabled
              class="input_field"
            />
            <svg
              @click="copy(this.selectedAddress.country)"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_2013)">
                <path
                  d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z"
                  fill="#3E414C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2013">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div class="shipments_select">
          <label>City</label>
          <div class="input">
            <input
              required
              :value="selectedAddress.city"
              disabled
              class="input_field"
            />
            <svg
              @click="copy(this.selectedAddress.city)"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_2013)">
                <path
                  d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z"
                  fill="#3E414C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2013">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div class="shipments_select">
          <label>State/Province/Region </label>
          <div class="input">
            <input
              required
              :value="selectedAddress.state"
              disabled
              class="input_field"
            />
            <svg
              @click="copy(this.selectedAddress.state)"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_2013)">
                <path
                  d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z"
                  fill="#3E414C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_2013">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div v-if="address === 'china'" @click="downloadPDF" class="button">
        <!-- <div class="button"> -->
        <button class="button_btn">Download sharable form</button>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userDetails: "",
      address: "",
      pdf: "https://novoinno-assets.s3.amazonaws.com/china-shipment-form.pdf",
      selectedAddress: [],
      addressesList: [
        {
          phoneNumber: "+44 7488 911139",
          addressLine: "34 crown house",
          zip: "DA1 1DZ",
          addressLine2: "Home Gardens",
          country: "United Kingdom (UK)",
          city: "Dartford",
          state: "Kent",
          key: "uk",
        },
        {
          phoneNumber: "+16479169511",
          addressLine: "1885 Clements Road",
          zip: "L1W 3V4",
          addressLine2: "Unit 212",
          country: "Canada",
          city: "Pickering",
          state: "Ontario",
          key: "canada",
        },
        {
          phoneNumber: "+1(281)8209000",
          addressLine: "11381 Meadowglen Lane",
          zip: "77082",
          addressLine2: "STE L",
          country: "United States Of America (USA)",
          city: "Houston",
          state: "Texas",
          key: "usa",
        },
        {
          phoneNumber: "",
          addressLine: "5 Adebo Close",
          zip: "100252",
          addressLine2: "5 Adebo Close",
          country: "Nigeria",
          city: "Ilupeju",
          state: "Lagos",
          key: "nigeria",
        },
        {
          name: "小雪冷",
          phoneNumber: "18529261378",
          addressLine: "67号楼，联非佳运仓库",
          zip: "510000",
          addressLine2: "000OJ 仓库, 广东省广州市白云区机场路兵房街7431号",
          country: "China",
          city: "广州",
          state: "广东",
          key: "china",
        },
      ],
    };
  },

  mounted() {
    this.getUserDetails();
  },
  watch: {
    address(newValue) {
      this.selectedAddress = this.addressesList.find(
        (address) => address.key === newValue
      );
    },
  },
  methods: {
    getUserDetails() {
      this.userDetails = this.$store.state.userDetails;
    },
    copy(data) {
      this.$copyText(data);
      this.$toast.success(`Copied`);
    },
    downloadPDF() {
      const link = document.createElement("a");
      link.href = this.pdf;
      link.download = this.pdf.split("/").pop();
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.addresses {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .addresses_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 37.25em;
    flex-shrink: 0;
    gap: 1.875rem;
    padding: 1.5rem;
    border-radius: 1.25rem;
    background: #fafafa;

    .button {
      button {
        width: 12.5em;
        margin-top: 24px;
        @media only screen and (max-width: 920px) {
          width: 100%;
        }
      }
    }

    .btn_wrapper {
      display: flex;
      width: 15.5em;
      height: 3.125em;
      margin-top: 1.875rem;
      padding: 12px 18px;
      align-items: center;
      background-color: $black-color;

      button {
        width: 12.5em;
        @media only screen and (max-width: 920px) {
          width: 100%;
        }
      }

      @media only screen and (max-width: 920px) {
        width: 100%;
      }
    }

    @media only screen and (max-width: 920px) {
      height: auto;
    }

    .addresses_wrapper_menu {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      width: 100%;

      p {
        color: #606060;
        font-family: $secondary-font;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media only screen and (max-width: 920px) {
          font-size: 0.75rem;
        }
      }

      .shipments_select {
        display: flex;
        flex-direction: column;
        gap: 0.9375rem;
        height: 4.5em;
        border-radius: 0.625rem;
        width: 12.5em;

        label {
          color: #606060 !important;
          font-family: $secondary-font;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .shipments_select_wrapper {
          border: 1px solid #dedce9;
          padding: 5px 0rem;
          width: 100%;
          height: 3.125em;
          display: flex;
          border-radius: 0.375rem;
          background-color: #fefefe;

          select {
            border-radius: 0.375rem;
            width: 100%;
            height: auto;
            background-color: #fefefe;

            color: #363539;
            border: none;
            border: 1px solid transparent;

            font-family: $secondary-font;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .addresses_wrapper_inner {
      display: flex;
      flex-wrap: wrap;
      gap: 2.5rem;
      @media only screen and (max-width: 920px) {
        flex-wrap: nowrap;
        flex-direction: column;
      }
      .shipments_select {
        display: flex;
        flex-direction: column;
        gap: 0.375rem;
        height: 2.5em;
        border-radius: 0.625rem;
        width: 35%;

        @media only screen and (max-width: 920px) {
          width: 100%;
        }

        label {
          color: #606060 !important;
          font-family: $secondary-font;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
